import store from "@/store";
import { getPlannerData, getEventPlannerData, createProgramPlanTask, updateProgramPlanTask, addPlanEvent, updatePlanEvent, deletedPlanEvent, deletedPlanTask, getUserInformation, postUserInformation } from "@/services/program/programPlannerService";
import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from "vuex-module-decorators";
import { IProgramPlanner, IProgramEvent, IProgramUserInfo } from '@/Model/programModel';


@Module({
  namespaced: true,
  name: "programPlanner",
  store,
  dynamic: true
})
class ProgramPlannerModule extends VuexModule {
  public plannerData: any = {};
  public plannerEventData: any = {};
  public progPlanTaskData: any = {};
  public updatedPlanTaskData: any = {};
  public updatedEventResData:any = {};
  public updatedEventRes:any = {};
  public deletedEventRes:any = {};
  public deletedTaskRes:any = {};
  public userInformationData:any={};


  get ProgPlannerList(){
    return this.plannerData;
  }

  get ProgPlanTaskRes(){
    return this.progPlanTaskData;
  }

  get UpdatedTaskRes(){
    return this.updatedPlanTaskData;
  }

  get EventAddResponse(){
    return this.updatedEventResData;
  }

  get UpdatedEvent(){
    return this.updatedEventRes;
  }

  get deletedEvent(){
    return this.deletedEventRes;
  }

  get deletedTask(){
    return this.deletedTaskRes;
  }

  @Mutation
  public getPlannerEventList(payload:any) {
    this.plannerEventData = payload
  }

  @Action({commit: "getPlannerEventList"})
  public plannerEventList(obj:any):Promise<Object>{
    let resultNew = async function listOfEventPlanners(): Promise<Object> {
      let promise = getEventPlannerData(obj);
      let result = await promise; 
      return result as object;
    }
    return resultNew();
  }

  @Mutation
  public getPlannerList(payload:any) {
    this.plannerData = payload
  }

  @Action({commit: "getPlannerList"})
  public plannerList(obj:number):Promise<Object>{
    let resultNew = async function listOfPlanners(): Promise<Object> {
      let promise = getPlannerData(obj);
      let result = await promise; 
      return result as object;
    }
    return resultNew();
  }


  @Mutation
  public ProgPlanTask(payload:any) {
    this.progPlanTaskData = payload
  }

  @Action({commit: "ProgPlanTask"})
  public async addProgramPlanner(payload: IProgramPlanner):Promise<Object>{
      let promise = createProgramPlanTask(payload);
      let result = await promise; 
      return result as object;
    }


    @Mutation
    public updatedPlanTask(payload:any) {
      this.updatedPlanTaskData = payload
    }
  
    @Action({commit: "updatedPlanTask"})
    public async updateProgramPlannerTask(payload: IProgramPlanner):Promise<Object>{
        let promise = updateProgramPlanTask(payload);
        let result = await promise;
        return result as object;
    }

      
    @Mutation
    public addEventUpdate(payload:any) {
       this.updatedEventResData = payload
    }


     @Action({commit:"addEventUpdate"})
     public async addProgramPlannerEvent(payload:IProgramEvent):Promise<Object>{
        let promise = addPlanEvent(payload);
        let result = await promise; 
        return result as object;
     } 
     
     @Mutation
     public UpdateEvent(payload:any) {
        this.updatedEventRes = payload
     }
     
      @Action({commit:"UpdateEvent"})
      public async updatedPlannerEvent(payload:IProgramEvent):Promise<Object>{
         let promise = updatePlanEvent(payload);
         let result = await promise; 
         return result as object;
      } 


      @Mutation
      public mutateDeletedEvent(payload:any) {
         this.deletedEventRes = payload
      }
  
       @Action({commit:"mutateDeletedEvent"})
       public async deleteEvent(payload:number):Promise<Object>{
          let promise = deletedPlanEvent(payload);
          let result = await promise;
          return result as object;
       } 

       @Mutation
       public mutateDeletedTask(payload:any) {
          this.deletedEventRes = payload
       }
   
        @Action({commit:"mutateDeletedTask"})
        public async deleteTask(payload:any):Promise<Object>{
           let promise = deletedPlanTask(payload);
           let result = await promise; 
           return result as object;
        } 

      @Mutation
      public mutateGetUserInformation(payload:any) {
        this.userInformationData = payload
      }
      @Action({commit: "mutateGetUserInformation"})
      public async getUserInformation(payload:any):Promise<Object>{
        let promise = getUserInformation(payload);
        let result = await promise; 
        return result as object;
     } 

     @Mutation
     public mutatePostUserInformation(payload:any) {
       this.userInformationData = payload
     }
     @Action({commit: "mutatePostUserInformation"})
     public async postUserInformation(payload: IProgramUserInfo):Promise<Object>{
       let promise = postUserInformation(payload);
       let result = await promise; 
       return result as object;
    }
}

export default getModule(ProgramPlannerModule);
